<template>
  <!-- <div class="caseIndex searchOff"> -->
  <div class="caseIndex" ref="caseIndex">
    <div class="pageTtlBlock">
      <h1 class="pageTtlBlock__ttl">
        CASE INDEX
        <span>想定課題一覧</span>
      </h1>
    </div>
    <div class="inner">
      <div class="caseIndex__search">
        <p class="caseIndex__search__ttl">
          課題を条件で検索
        </p>
        <div class="caseIndex__search__inner">
          <div class="cate1">
            <div
              class="cate1__change"
              :class="cate1Change == 'cate1-case' ? 'case' : 'process'"
            >
              <input
                id="cate1-case"
                v-model="cate1Change"
                value="cate1-case"
                name="cate1"
                type="radio"
                checked
                @change="reset()"
              />
              <label for="cate1-case" class="cate1ChangeBtn">
                想定別
              </label>
              <input
                id="cate1-process"
                v-model="cate1Change"
                value="cate1-process"
                name="cate1"
                type="radio"
                @change="reset()"
              />
              <label for="cate1-process" class="cate1ChangeBtn">
                工程別
              </label>
            </div>
            <div v-show="cate1Change == 'cate1-case'" class="categories">
              <input
                id="case1"
                v-model="checkCates"
                value="新たに検査現場を構築したい"
                type="checkbox"
                name="cate1-case"
                :disabled="isDisabled.case1"
              />
              <label for="case1">
                新たに検査現場を構築したい
              </label>
              <input
                id="case2"
                v-model="checkCates"
                value="既存の検査場を改修したい"
                type="checkbox"
                name="cate1-case"
                :disabled="isDisabled.case2"
              />
              <label for="case2">
                既存の検査場を改修したい
              </label>
              <input
                id="case3"
                v-model="checkCates"
                value="現状の課題を確認したい"
                type="checkbox"
                name="cate1-case"
                :disabled="isDisabled.case3"
              />
              <label for="case3">
                現状の課題を確認したい
              </label>
              <input
                id="case4"
                v-model="checkCates"
                value="検査現場を省人化したい"
                type="checkbox"
                name="cate1-case"
                :disabled="isDisabled.case4"
              />
              <label for="case4">
                検査現場を省人化したい
              </label>
            </div>
            <div v-show="cate1Change == 'cate1-process'" class="categories">
              <input
                id="pro1"
                v-model="checkCates"
                value="塗装完成車工程"
                type="checkbox"
                :disabled="isDisabled.pro1"
              />
              <label for="pro1">
                塗装完成車工程
              </label>
              <input
                id="pro2"
                v-model="checkCates"
                value="エンジン・パワートレイン工程"
                type="checkbox"
                :disabled="isDisabled.pro2"
              />
              <label for="pro2">
                エンジン・パワートレイン工程
              </label>
              <input
                id="pro3"
                v-model="checkCates"
                value="ボデー・プレス工程"
                type="checkbox"
                :disabled="isDisabled.pro3"
              />
              <label for="pro3">
                ボデー・プレス工程
              </label>
            </div>
          </div>
          <div class="cate2">
            <div class="categories">
              <p class="cate2__ttl">
                担当領域
              </p>
              <input
                id="flow1"
                v-model="checkCates"
                value="現場調査"
                type="checkbox"
                :disabled="isDisabled.flow1"
              />
              <label for="flow1">
                現場調査
              </label>
              <input
                id="flow2"
                v-model="checkCates"
                value="施策策定"
                type="checkbox"
                :disabled="isDisabled.flow2"
              />
              <label for="flow2">
                施策策定
              </label>
              <input
                id="flow3"
                v-model="checkCates"
                value="コンサルティング"
                type="checkbox"
                :disabled="isDisabled.flow3"
              />
              <label for="flow3">
                コンサルティング
              </label>
              <input
                id="flow4"
                v-model="checkCates"
                value="製品開発"
                type="checkbox"
                :disabled="isDisabled.flow4"
              />
              <label for="flow4">
                製品開発
              </label>
              <input
                id="flow5"
                v-model="checkCates"
                value="納品後サポート"
                type="checkbox"
                :disabled="isDisabled.flow5"
              />
              <label for="flow5">
                納品後サポート
              </label>
            </div>
          </div>
        </div>
      </div>
      <transition-group
        tag="ul"
        name="fade"
        class="caseIndex__contents"
        @before-enter="beforeEnter"
        @after-enter="afterEnter"
        @enter-cancelled="afterEnter"
      >
        <li
          v-for="(item, index) in displayContents"
          :data-index="index"
          :key="item.ttl"
          class="caseIndex__contents__block"
        >
          <router-link :to="{ name: item.path}">
            <p class="caseIndex__contents__block__img">
              <img :src="'/genbashiko/img/case/' + item.path.toLowerCase() + '/mv.jpg'" alt="" />
            </p>
            <p class="caseIndex__contents__block__ttl" v-html="item.ttl"></p>
            <p class="caseIndex__contents__block__more">more</p>
          </router-link>
        </li>
      </transition-group>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cate1Change: "cate1-case",
      checkCates: [],
      // checkCate2: null, //工程別のみラジオのため
      isDisabled: {},
      datas: null,
      displayContents: [],
      categoryDatas: null,
    };
  },
  head: {
    title: {
      inner: '想定課題一覧'
    },
    meta: [
      { property: 'og:title', content: '想定課題一覧｜GENBA SHIKO' },
      { name: 'description', content: '自動車製造工場が抱える製造現場に関する様々な課題や問題に対する提案事例を、照明器具の具体例や当社提供サービス内容を交えてご紹介します。' },
      { property: 'og:description', content: '自動車製造工場が抱える製造現場に関する様々な課題や問題に対する提案事例を、照明器具の具体例や当社提供サービス内容を交えてご紹介します。' },
      { property: 'og:url', content: 'https://luci.co.jp/genbashiko/case' },
      { property: 'og:type', content: 'article' }
    ]
  },
  mounted: function() {
    this.categoryDatas = [
      {
        type: "0",
        cate: "case1",
        name: "新たに検査現場を構築したい",
      },
      {
        type: "0",
        cate: "case2",
        name: "既存の検査場を改修したい",
      },
      {
        type: "0",
        cate: "case3",
        name: "現状の課題を確認したい",
      },
      {
        type: "0",
        cate: "case4",
        name: "検査現場を省人化したい",
      },
      {
        type: "1",
        cate: "pro1",
        name: "塗装完成車工程",
      },
      {
        type: "1",
        cate: "pro2",
        name: "エンジン・パワートレイン工程",
      },
      {
        type: "1",
        cate: "pro3",
        name: "ボデー・プレス工程",
      },
      {
        type: "2",
        cate: "flow1",
        name: "現場調査",
      },
      {
        type: "2",
        cate: "flow2",
        name: "施策策定",
      },
      {
        type: "2",
        cate: "flow3",
        name: "コンサルティング",
      },
      {
        type: "2",
        cate: "flow4",
        name: "製品開発",
      },
      {
        type: "2",
        cate: "flow5",
        name: "納品後サポート",
      },
    ];

    this.datas = [
      {
        ttl: `塗装検査工程のライン照明の接続部に<br>切れ目が生じて不良を見逃しやすい`,
        path: "Case01",
        category: [
          "既存の検査場を改修したい",
          "検査現場を省人化したい",
          "塗装完成車工程",
          "現場調査",
          "施策策定",
          "コンサルティング",
          "製品開発",
          "納品後サポート",
        ],
      },
      {
        ttl:
          "照明のプロが不在で課題が把握されず<br>最適な照明や光環境が構築できない",
        path: "Case02",
        category: [
          "新たに検査現場を構築したい",
          "現状の課題を確認したい",
          "塗装完成車工程",
          "現場調査",
          "施策策定",
          "コンサルティング",
          "製品開発",
          "納品後サポート",
        ],
      },
      {
        ttl:
          "エンジンなど複雑な形状のワークで<br>影が発生して不良の見落としが発生",
        path: "Case03",
        category: [
          "既存の検査場を改修したい",
          "エンジン・パワートレイン工程",
          "現場調査",
          "施策策定",
          "コンサルティング",
          "製品開発",
          "納品後サポート",
        ],
      },
      {
        ttl: "強すぎる照明や反射によって<br>作業員の眼の疲れが生じやすい",
        path: "Case04",
        category: [
          "現状の課題を確認したい",
          "エンジン・パワートレイン工程",
          "現場調査",
          "施策策定",
          "コンサルティング",
          "製品開発",
          "納品後サポート",
        ],
      },
      {
        ttl:
          "塗装前のボデーの不良が見えにくく<br>検査作業に多大な手間とコストが必要",
        path: "Case05",
        category: [
          "既存の検査場を改修したい",
          "検査現場を省人化したい",
          "ボデー・プレス工程",
          "現場調査",
          "施策策定",
          "コンサルティング",
          "製品開発",
          "納品後サポート",
        ],
      },
      {
        ttl:
          `<span style="font-size:20px;">1つの塗装検査ラインで様々な塗色のワークに合った<br>照明の明るさや光源色で検査ができない</span>`,
        path: "Case06",
        category: [
          "新たに検査現場を構築したい",
          "既存の検査場を改修したい",
          "検査現場を省人化したい",
          "コンサルティング",
          "製品開発",
          "納品後サポート"
        ],
      },
      {
        ttl:
          `塗装検査ブースの環境を改善するために<br>手間・時間・コストがかかる`,
        path: "Case07",
        category: [
          "新たに検査現場を構築したい",
          "既存の検査場を改修したい",
          "現状の課題を確認したい",
          "塗装完成車工程",
          "ボデー・プレス工程"
        ],
      },
      {
        ttl:
          `塗装後検査における適切な照明ピッチが<br>分からない`,
        path: "Case08",
        category: [
          "新たに検査現場を構築したい",
          "既存の検査場を改修したい",
          "現状の課題を確認したい",
          "現場調査",
          "施策策定",
          "コンサルティング",
          "製品開発",
          "塗装完成車工程"
        ],
      },
      {
        ttl:
          `ワークに適した照明のピッチ間隔で<br>ゼブラ検査ができない`,
        path: "Case09",
        category: [
          "新たに検査現場を構築したい",
          "既存の検査場を改修したい",
          "現状の課題を確認したい",
          "塗装完成車工程",
          "ボデー・プレス工程"
        ],
      }
    ];
    this.initDisabled();
    let para = this.$route.query.category;
    if (para && !this.$refs.caseIndex.classList.contains("searchOff")) {
      this.categoryDatas.forEach((v) => {
        if (v.name == para) {
          if (v.type == 0) this.cate1Change = "cate1-case";
          else this.cate1Change = "cate1-process";

          this.checkCates = [para];
          // if (v.type == 0 || v.type == 2) this.checkCates = [para];
          // else if (v.type == 1) this.checkCate2 = para;
          return;
        }
      });
    }
    this.initDatas();
  },
  watch: {
    checkCates: function() {
      // if (!this.checkCate2 && this.checkCates.length <= 0) {
      if (this.checkCates.length <= 0) {
        this.initDatas();
      } else {
        this.setData();
      }
    },
    // checkCate2: function() {
    //   if (!this.checkCate2 && this.checkCates.length <= 0) {
    //     this.initDatas();
    //   } else {
    //     this.setData();
    //   }
    // },
  },
  methods: {
    initDatas: function() {
      this.displayContents = this.datas.slice(0, this.datas.length);
      this.resetDisabled();
    },
    setData: function() {
      this.displayContents = [];
      this.$nextTick(() => {
        this.datas.forEach((i) => {
          let cate = i["category"];
          if (cate.length) {
            if (this.checkCates.every((el) => cate.includes(el))) {
              // if (this.checkCate2 && !cate.includes(this.checkCate2)) return;
              this.displayContents.push(i);
            }
          }
        });
        this.setDisabled();
      });
      // console.log(this.displayContents);
    },
    initDisabled: function() {
      this.categoryDatas.forEach((v) => {
        this.isDisabled[v.cate] = false;
      });
    },
    setDisabled: function() {
      Object.keys(this.isDisabled).forEach((v) => {
        this.isDisabled[v] = true;
      });

      this.displayContents.forEach((v) => {
        v.category.forEach((c) => {
          const n = this.categoryDatas.find((e) => e.name == c);
          this.isDisabled[n.cate] = false;
        });
      });
    },
    // トランジション開始でインデックス*100ms分のディレイを付与
    beforeEnter(el) {
      el.style.transitionDelay = 100 * parseInt(el.dataset.index, 10) + "ms";
    },
    // トランジション完了またはキャンセルでディレイを削除
    afterEnter(el) {
      el.style.transitionDelay = "";
    },

    resetDisabled: function() {
      Object.keys(this.isDisabled).forEach((v) => {
        this.isDisabled[v] = false;
      });
    },
    reset() {
      this.checkCates = [];
      // this.checkCate2 = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.caseIndex {
  .pageTtlBlock {
    &::after {
      background: url("/genbashiko/img/case/index/bg.jpg") center/cover no-repeat;
    }
  }
  &.searchOff {
    .caseIndex__search {
      display: none;
    }
    .caseIndex__contents {
      margin-top: 70px;
    }
  }
  &__search {
    &__ttl {
      text-align: left;
      margin-top: 30px;
      font-size: 1.6rem;
    }
    &__inner {
      margin-top: 20px;
      border: 1px solid #cec9c3;
      padding: 40px 40px 30px;
      .cate1 {
        &__change {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 150px;
            height: 35px;
            border-radius: 17.5px;
            background: #f6f5f3;
            left: 0;
            top: 0;
          }
          &::after {
            content: "";
            position: absolute;
            width: 150px;
            height: 35px;
            border-radius: 17.5px;
            left: 0;
            top: 0;
            background: $orColor;
            transition: all 0.2s ease-in;
            z-index: 1;
          }
          &.case {
            &::before {
              transform: translateX(170px);
            }
            &::after {
              transform: translateX(0);
              transition: all 0.2s ease-out;
            }
          }
          &.process {
            &::before {
              transform: translateX(0);
            }
            &::after {
              transform: translateX(170px);
              transition: all 0.2s ease-out;
            }
          }
        }
      }
      input[name="cate1"] {
        display: none;
        + label {
          width: 150px;
          height: 35px;
          line-height: 35px;
          border-radius: 17.5px;
          font-size: 1.8rem;
          display: inline-block;
          text-align: center;
          position: relative;
          z-index: 10;
          transition: color 0.1s ease-in;
          + input + label {
            margin-left: 20px;
          }
        }
        &:checked + label {
          color: #fff;
          background: none;
          transition: color 0.1s ease-out;
        }
      }
      .categories {
        margin: 10px 0 0 -60px;
        input[type="checkbox"],
        input[type="radio"] {
          display: none;
          + label {
            position: relative;
            margin: 20px 0 0 60px;
            display: inline-block;
            transition: all 0.2s ease-in;
            &::before,
            &::after {
              content: "";
              display: inline-block;
              transition: all 0.2s ease-out;
            }
            &::before {
              border: 2px solid #cec9c3;
              border-radius: 50%;
              width: 18px;
              height: 18px;
              margin-right: 10px;
              margin-bottom: -4px;
            }
            &::after {
              position: absolute;
              width: 10px;
              height: 10px;
              background: transparent;
              border-radius: 50%;
              left: 4px;
              bottom: 3px;
            }
          }
          &:disabled + label {
            opacity: 0.5;
            cursor: inherit;
          }
          &:checked + label {
            &::before {
              border-color: $orColor;
            }
            &::after {
              background: $orColor;
            }
          }
        }
      }
      .cate2 {
        padding-top: 25px;
        margin-top: 30px;
        border-top: 1px solid #cec9c3;
        &__ttl {
          text-align: left;
          margin-left: 60px;
        }
      }
    }
  }
  &__contents {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .fade-enter-active {
      transition: opacity 1s, transform 1s ease;
    }
    .fade-enter {
      opacity: 0;
      transform: translateY(50px);
    }
    .fade-enter-to {
      opacity: 1;
    }
    &__block {
      flex: 0 0 47%;
      margin-top: 50px;
      &:hover {
        .caseIndex__contents__block__img {
          opacity: 0.7;
          transition: all 0.3s ease-out;
        }
        .caseIndex__contents__block__more {
          color: #fff;
          background: $orColor;
          border-color: $orColor;
          transition: all 0.3s ease-out;
          &::before,
          &::after {
            background: #fff;
            transition: all 0.3s ease-out;
          }
        }
      }
      &__ttl {
        font-size: 2.2rem;
        text-align: left;
        margin-top: 20px;
      }
      &__img {
        transition: all 0.3s ease-in;
      }
      &__more {
        position: relative;
        width: 150px;
        height: 30px;
        font-family: $Lato;
        border: 1px solid #000;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        margin-top: 20px;
        padding-right: 20px;
        transition: all 0.3s ease-in;
        &::before,
        &::after {
          content: "";
          position: absolute;
          background: #000;
          transition: all 0.3s ease-in;
        }
        &::before {
          width: 19px;
          height: 1px;
          top: 17px;
          right: 36px;
        }
        &::after {
          width: 8px;
          height: 1px;
          transform: rotate(45deg);
          top: 14px;
          right: 35px;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .caseIndex {
    &__search {
      &__ttl {
        font-size: 1.2rem;
        margin-top: 30px;
      }
      &__inner {
        padding: 20px;
        .categories {
          margin: 0 0 0 -30px;
          input[type="checkbox"] {
            + label {
              font-size: 1rem;
              margin: 20px 0 0 30px;
              &::after {
                bottom: 2px;
              }
            }
          }
        }
        .cate1 {
          &__change {
            &::before ,&::after {
              width: 90px;
              height: 27px;
              border-radius: 13.5px;
            }
            &.case {
              &::before {
                transform: translateX(106px);
              }
              &::after {
                transform: translateX(0);
              }
            }
            &.process {
              &::before {
                transform: translateX(0);
              }
              &::after {
                transform: translateX(106px);
              }
            }
          }
          input[type="radio"] {
            display: none;
            + label {
              width: 90px;
              height: 27px;
              line-height: 27px;
              border-radius: 13.5px;
              font-size: 1rem;
              + input + label {
                margin-left: 15px;
              }
            }
          }
        }
        .cate2 {
          margin-top: 20px;
          padding-top: 20px;
          &__ttl {
            font-size: 1rem;
            margin-left: 30px;
          }
        }
      }
    }
    &__contents {
      display: block;
      margin-top: 50px;
      &__block {
        &__ttl {
          font-size: 1.4rem;
        }
        &__more {
          width: 90px;
          height: 20px;
          font-size: 0.9rem;
          margin-top: 15px;
          &::before {
            width: 13px;
            top: 12px;
            right: 22px;
          }
          &::after {
            width: 6px;
            top: 10px;
            right: 21px;
          }
        }
      }
    }
  }
}
</style>
